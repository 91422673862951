// components/VideoSection.js
import React, { useEffect, useRef } from 'react';
import videoSrc from '../assets/imagenes_videos/PRESENTACION 5.mp4';
import videoPoster from '../assets/imagenes_videos/video_poster.PNG';

const VideoSection = ({ videoStarted, handleVideoStart }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!videoRef.current) return;
      const rect = videoRef.current.getBoundingClientRect();
      const inView = rect.top >= 0 && rect.bottom <= window.innerHeight;

      if (inView && videoRef.current.paused) {
        videoRef.current.play();
      } else if (!inView && !videoRef.current.paused) {
        videoRef.current.pause();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="d-flex justify-content-center align-items-center" style={{ height: '60vh', position: 'relative' }}>
            {!videoStarted ? (
              <>
                <img src={videoPoster} alt="Vista previa del video" className="mw-100 mh-100" style={{ objectFit: 'cover' }} />
                <button onClick={handleVideoStart} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: '10px 20px', backgroundColor: '#000', color: '#fff', border: 'none', cursor: 'pointer' }}>
                  Reproducir Video
                </button>
              </>
            ) : (
              <video ref={videoRef} id="miVideo" className="mw-100 mh-100" controls autoPlay poster={videoPoster}>
                <source src={videoSrc} type="video/mp4" />
                Tu navegador no soporta la reproducción de video.
              </video>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
