// components/InformationSection.js
import React from 'react';
import ImageCarousel from './ImageCarousel';

const InformationSection = () => (
  <section id="informationSection" className="py-3" data-aos="zoom-in">
    <div className="container">
      <div className="row mb-4">
        <div className="col-12 text-justify" data-aos="fade-right" data-aos-duration="2000">
          <h4 className="text-center mb-4" style={{ fontFamily: 'MarvinVision' }}>
            El 90% de las personas no logran sus objetivos sin ayuda.<small>[Harkin et al., 2016]</small>
          </h4>
          <p className="mb-4" style={{ fontFamily: 'AcuminPro' }}>
            El seguimiento personalizado aumenta tus probabilidades de éxito significativamente. Con mi asesoría, estarás en el camino correcto.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-center" data-aos="fade-left" data-aos-duration="2000">
          <h4 className="text-uppercase mb-4" style={{ fontFamily: 'MarvinVision' }}>
            ¿Qué crees que hace ese 10%?
          </h4>
          <p style={{ fontFamily: 'AcuminPro' }}>
            Resultados después de tomar la mejor decisión
          </p>
          <ImageCarousel />
        </div>
      </div>
    </div>
  </section>
);

export default InformationSection;