// components/ImageCarousel.js
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import carrouselImages from '../Utils/CarrouselImages';

const ImageCarousel = () => (
  <Carousel showArrows={true} infiniteLoop={true} showThumbs={false} showStatus={false} autoPlay={false}>
    {carrouselImages.map((image, index) => (
      <div key={index} className="carousel-image-container">
        <img src={image} alt={`Slide ${index + 1}`} className="carousel-image" />
      </div>
    ))}
  </Carousel>
);

export default ImageCarousel;