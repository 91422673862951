// components/JoinSection.js
import React from 'react';

const JoinSection = () => (
  <section id="joinSection" className="text-center py-5" data-aos="zoom-in">
    <div className="container">
      <h2 className="text-uppercase" style={{ fontFamily: 'MarvinVisions', fontWeight: 'bold', color: '#5296FF' }}>
        Únete tú también al 10% que consigue transformar su vida fitness
      </h2>
    </div>
  </section>
);

export default JoinSection;