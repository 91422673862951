const benefitsImages = [
    { src: require('../assets/imagenes_videos/1.png'), text: 'Personalización' },
    { src: require('../assets/imagenes_videos/2.png'), text: 'Motivación Constante' },
    { src: require('../assets/imagenes_videos/3.png'), text: 'Responsabilidad' },
    { src: require('../assets/imagenes_videos/4.png'), text: 'Apoyo emocional y mental' },
    { src: require('../assets/imagenes_videos/5.png'), text: 'Educación' },
    { src: require('../assets/imagenes_videos/6.png'), text: 'Resultados' },
    { src: require('../assets/imagenes_videos/7.png'), text: 'Flexibilidad' },
    { src: require('../assets/imagenes_videos/9.png'), text: 'Comunidad y pertenencia' }
  ];
  
export default benefitsImages;
  