// components/BenefitsSection.js
import React from 'react';
import benefitsImages from '../Utils/BenefitsImages';

const BenefitsSection = () => (
  <section id="benefitsSection" className="py-3 mt-5">
    <div className="container">
      <h2 className="titulos-fondo text-center mb-5 text-uppercase text-light bg-primary" style={{ fontWeight: 'bold', fontSize: '1.5rem' }} data-aos="zoom-in">
        Beneficios de Entrenar con un Coach
      </h2>
      <div className="row justify-content-center">
        {benefitsImages.map((benefit, index) => (
          <div key={index} className="col-6 d-flex justify-content-center">
            <div className="card benefitsCard">
              <img src={benefit.src} className="card-img-top" alt={`Beneficio ${index + 1}`} />
              <div className="card-body">
                <p className="card-text text-center">{benefit.text}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default BenefitsSection;