// components/LimitedSlotsSection.js
import React from 'react';

const LimitedSlotsSection = () => (
  <>
    <h3 className="text-center bg-primary text-light mx-3 mb-0 mt-3" style={{ fontWeight: 'bold' }} data-aos="zoom-in">¡LAS PLAZAS SON LIMITADAS!</h3>
    <h3 className="text-center bg-primary text-light mx-3 mb-3" style={{ fontWeight: 'bold' }} data-aos="zoom-in">¿A QUÉ ESPERAS?</h3>
  </>
);

export default LimitedSlotsSection;