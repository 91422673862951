// components/GiftSection.js
import React from 'react';
import regaloImg from '../assets/imagenes_videos/regalo landing.png';

const GiftSection = () => {
  const irAPaginaContacto = () => {
    window.location.href = 'http://eepurl.com/iOvC1Y';
  };

  return (
    <div className="container">
      <div className="row text-center pointer" data-aos="fade-up">
        <div className="col-12">
          <div onClick={irAPaginaContacto} style={{ cursor: 'pointer' }}>
            <p className="titulos-fondo mb-0 p-1 text-uppercase bg-primary text-light mx-5 rounded" style={{ fontSize: '1.2rem' }}>
              Recibe tu regalo gratuito
            </p>
            <img
              src={regaloImg}
              alt="Regalo Gratis"
              className="jump img-fluid mx-auto d-block"
              style={{ maxWidth: '50%' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiftSection;