const carrouselImages = [
    require('../assets/imagenes_videos/Carrousel9.webp'),
    require('../assets/imagenes_videos/Carrousel10.webp'),
    require('../assets/imagenes_videos/Carrousel2.webp'),
    require('../assets/imagenes_videos/Carrousel1.webp'),
    require('../assets/imagenes_videos/Carrousel5.webp'),
    require('../assets/imagenes_videos/Carrousel4.webp'),
    require('../assets/imagenes_videos/Carrousel3.webp'),
    require('../assets/imagenes_videos/Carrousel6.webp'),
    require('../assets/imagenes_videos/Carrousel13.jpeg'),
    require('../assets/imagenes_videos/Carrousel14.jpeg'),
    require('../assets/imagenes_videos/Carrousel7.webp'),
    require('../assets/imagenes_videos/Carrousel8.webp'),
    require('../assets/imagenes_videos/Carrousel11.webp'),
    require('../assets/imagenes_videos/Carrousel12.webp')
  ];
  
export default carrouselImages;
  