// components/BookSection.js
import React from 'react';
import fotoDiario from '../assets/imagenes_videos/foto_diario.jpg';

const BookSection = () => (
  <section id="bookSection" className="py-5 text-center" style={{ backgroundColor: '#000000', color: '#ffffff' }}>
    <div className="container">
      <h2 className="text-uppercase" style={{ fontFamily: 'MarvinVisions', fontWeight: 'bold', color: '#5296FF' }}>
        El mejor Diario de Entrenamiento
      </h2>
      <p style={{ fontFamily: 'AcuminPro', fontSize: '1.25rem', margin: '20px 0' }}>
        Máximiza tu entrenamiento con esta increíble herramienta, te acompañará todos los días en tus sesiones y se ocupará de llevarte a otro nivel pudiendo registrar y cuantificar todo el progreso. Con este diario te mantendrás motivado al ver resultados.
      </p>
      <img 
        src={fotoDiario}
        alt="Diario de Entrenamiento"
        className="img-fluid mb-4"
        style={{ maxWidth: '100px', borderRadius: '15px', boxShadow: '0 4px 8px rgba(255, 255, 255, 0.2)' }}
      />
      <div>
        <a 
          href="https://buy.stripe.com/eVag0A3dJ59lcBGeVa" 
          target="_blank" 
          rel="noopener noreferrer"
          className="btn btn-success btn-lg"
          style={{ 
            fontFamily: 'MarvinVisions', 
            fontWeight: 'bold', 
            padding: '10px 30px', 
            animation: 'moveUpDown 2s infinite'
          }}
        >
          ¡Compra Ahora!
        </a>
        <p className="mt-2" style={{ fontFamily: 'AcuminPro', color: '#ffffff',fontSize: '1.25rem', margin: '20px 0' }}>
          Descubre cuáles son los 5 bonos de regalo que obtendrás por tu compra con envío gratis.
        </p>
      </div>
    </div>
  </section>
);

export default BookSection;
