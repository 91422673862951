// components/AssessmentsSection.js
import React from 'react';
import AssessmentPlan from './AssessmentPlan';
import { trimestralItems, semestralItems } from '../Utils/AssessmentData';

const AssessmentsSection = () => (
  <section id="assessmentsSection" className="py-5">
    <div className="container">
      <h2 className="text-center mb-3 text-uppercase text-light bg-primary" style={{ fontWeight: 'bold' }} data-aos="zoom-in">
        Asesorías Únicas
      </h2>
      <AssessmentPlan title="Trimestral" items={trimestralItems} />
      <AssessmentPlan title="Semestral" items={semestralItems} />
    </div>
  </section>
);

export default AssessmentsSection;