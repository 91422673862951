export const trimestralItems = [
  { id: 'asesoramiento', text: 'Asesoramiento personalizado vía WhatsApp', content: 'Un enlace permanente: WhatsApp abierto las 24/7, solo para ti😉' },
  { id: 'psicoanalisis', text: 'Psicoanálisis Inicial', content: 'Un vistazo a tu mundo interior para afinar tu asesoría, conectando mente y cuerpo en tres meses de cambio.' },
  { id: 'nutricion', text: 'Plan de nutrición ( pautas y orientación)', content: 'No solo aprenderás de las mejores recetas y cambiarás tu mente y físico, sino que te volverás un chef de los mejores platos. Ahora con pautas y orientación adicionales para maximizar tus resultados.' },
  { id: 'appprivada', text: 'Acceso a una app privada', content: 'Tendrás entrada a una app personalizada totalmente para ti y tus objetivos' },
  { id: 'entrenamiento', text: 'Plan de entrenamiento diario ajustado a ti', content: 'Llamémoslo como quieras, pero te aseguro que será impactante y revolucionario' },
  { id: 'llamadas', text: '1 llamada semanal 1 a 1', content: 'Más que una llamada, una conexión transformadora hacia tu mejor versión' },
  { id: 'videos', text: 'Videos explicativos', content: 'Recibe videos detallados que te guiarán a lo largo de tu programa de entrenamiento y nutrición.' },
  { id: 'reporte', text: 'Reporte y revisión semanal', content: 'Cada semana recibirás un reporte detallado con revisiones para mantenerte en el camino hacia tus metas.' },
  { id: 'regalo', text: 'REGALO SORPRESA', content: 'Descubre cuál será tu obsequio secreto con el que revolucionarás tus entrenamientos.' },
  { id: 'terapeuta', text: 'Terapeuta y psicoanalista', content: 'Imagina un cambio radical, donde tu mente y cuerpo se alinean con ayuda terapéutica semanal. Eso es evolución', disabled: true },
  { id: 'entrenamiento-presencial', text: '1 entrenamiento al mes presencial conmigo', content: '¿Listo para transformarte en persona? Si estás en Madrid, no te pierdas esta oportunidad exclusiva: entrena conmigo, cara a cara, una vez al mes. ¿Te unes al cambio?', disabled: true }
];

export const semestralItems = [
  { id: 'asesoramiento2', text: 'Asesoramiento personalizado vía WhatsApp', content: 'Un enlace permanente: WhatsApp abierto las 24/7, solo para ti😉' },
  { id: 'psicoanalisis2', text: 'Psicoanálisis Inicial', content: 'Una inmersión en tus motivaciones para crear una asesoría que transforme mente y cuerpo en seis meses.' },
  { id: 'nutricion2', text: 'Plan de nutrición ( pautas y orientación)', content: 'No solo aprenderás de las mejores recetas y cambiarás tu mente y físico, sino que te volverás un chef de los mejores platos. Ahora con pautas y orientación adicionales para maximizar tus resultados.' },
  { id: 'appprivada2', text: 'Acceso a una app privada', content: 'Tendrás entrada a una app personalizada totalmente para ti y tus objetivos' },
  { id: 'entrenamiento2', text: 'Plan de entrenamiento diario ajustado a ti', content: 'Llamémoslo como quieras, pero te aseguro que será impactante y revolucionario' },
  { id: 'cuatro-llamadas2', text: '3 llamadas semanales 1 a 1', content: 'Más que una llamada, una conexión transformadora hacia tu mejor versión. Ahora no es solo una, sino tres. Me recuerda ese sentimiento de euforia al ver tus avances y querer impulsarte aún más' },
  { id: 'terapeuta2', text: 'Terapeuta y psicoanalista', content: 'Imagina un cambio radical, donde tu mente y cuerpo se alinean con ayuda terapéutica semanal. Eso es evolución' },
  { id: 'entrenamiento-presencial2', text: '1 entrenamiento al mes presencial conmigo', content: '¿Listo para transformarte en persona? Si estás en Madrid, no te pierdas esta oportunidad exclusiva: entrena conmigo, cara a cara, una vez al mes. ¿Te unes al cambio?' },
  { id: 'videos2', text: 'Videos explicativos', content: 'Recibe videos detallados que te guiarán a lo largo de tu programa de entrenamiento y nutrición.' },
  { id: 'reporte2', text: 'Reporte y revisión semanal', content: 'Cada semana recibirás un reporte detallado con revisiones para mantenerte en el camino hacia tus metas.' },
  { id: 'regalo', text: 'REGALO SORPRESA', content: 'Descubre cuál será tu obsequio secreto con el que revolucionarás tus entrenamientos.' }
];
