// components/AssessmentPlan.js
import React, { useState } from 'react';

const AssessmentPlan = ({ title, items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleItem = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="accordion mb-4" id={`accordion-${title.toLowerCase()}`}>
      <h2 className="text-center text-uppercase" style={{ fontWeight: 'bold' }}>{title}</h2>
      {items.map((item, index) => (
        <div key={index} className="card">
          <div
            className="card-header"
            id={`heading-${title}-${index}`}
            onClick={() => toggleItem(index)}
            style={{ cursor: 'pointer' }}
          >
            <h3 className="mb-0">
              <button
                className="btn btn-link btn-block text-left"
                type="button"
                aria-expanded={activeIndex === index}
                aria-controls={`collapse-${title}-${index}`}
                style={item.disabled ? { textDecoration: 'line-through', cursor: 'not-allowed', color: 'gray' } : {}}
                disabled={item.disabled}
              >
                {item.text}
              </button>
            </h3>
          </div>
          {!item.disabled && (
            <div
              id={`collapse-${title}-${index}`}
              className={`collapse ${activeIndex === index ? 'show' : ''}`}
              aria-labelledby={`heading-${title}-${index}`}
              data-parent={`#accordion-${title.toLowerCase()}`}
            >
              <div className="card-body">
                {item.content}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default AssessmentPlan;
