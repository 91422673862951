const faqItems = [
    {
      question: '¿Cómo recibo el programa?',
      answer: 'El programa está disponible en mi app personalizada, cuando realices el pago, te llegará un mensaje con el paso a paso que debes seguir.'
    },
    {
      question: '¿Es un programa basado en suscripción?',
      answer: 'Si, porque el secreto de un gran cambio está en el mantenimiento, y ahí es donde aparezco yo. Tienes la opción de optar por el programa trimestral o semestral. Los programas se actualizan constantemente con entrenamiento y comidas adaptadas a ti según el programa que elijas, además de todo el apoyo continuo que vas a recibir por parte de todo el equipo.'
    },
    {
      question: '¿Los programas estan disponibles en todo el mundo?',
      answer: '¡Claro! Estés dónde estés, te ayudaré al 100% en todo momento.'
    },
    {
      question: '¿Puedo hacer el plan en casa?',
      answer: 'Puedo adaptar el plan para que lo hagas en cualquier sitio, hasta en casa.'
    },
    {
      question: 'Reembolsos y devoluciones',
      answer: 'Te ofrezco la garantía de devolverte el 100% de tu dinero si no alcanzas el objetivo. ¡Aunque te tengo que decir que lo conseguirás antes de darte cuenta! 😉',
      link: require('../assets/Términos y condiciones ISMARFIT.pdf')
    },
    {
      question: 'Soy nuevo en el gimnasio. ¿Sirve para principantes?',
      answer: 'El método está diseñado para cualquier tipo de nivel y de persona. Tú eliges.'
    },
    {
      question: '¿Todos los meses son iguales?',
      answer: 'La asesoría se basa en una trimestral y otra semestral y cada una está dividida en varias fases en las que poco a poco irás cumpliendo tus objetivos.'
    }
  ];
  
  export default faqItems;
  