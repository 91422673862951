
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/imagenes_videos/Logo.png';

const Navbar = () => {
  return (
    <nav className="navbar navbar-light bg-light" style={{ maxWidth: '100vw' }}>
      <div className="container d-flex justify-content-between">
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="Transforma tu Vida Fitness" width="100" height="50" />
        </Link>
        <a 
          className="btn btn-success text-light" 
          href="https://chat.whatsapp.com/IZ1QwzSyZFX0fdMkcVNwEd"
          style={{ fontSize: '1rem' }}
        >
          Accede a la comunidad
        </a>
      </div>
    </nav>
  );
};

export default Navbar;