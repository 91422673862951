// PaginaPrincipal.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'aos/dist/aos.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'aos/dist/aos.js';
import 'jquery/dist/jquery.min.js';
import '../functionality/paginaprincipal.js';
import '../components/css/PaginaPrincipal.css';
import Navbar from '../components/Navbar.jsx';
import ModalPopup from '../components/ModalPopup.jsx';
import HeaderSection from '../components/HeaderSection.jsx';
import VideoSection from '../components/VideoSection.jsx';
import InformationSection from '../components/InformationSection.jsx';
import JoinSection from '../components/JoinSection.jsx';
import GiftSection from '../components/GiftSection.jsx';
import BenefitsSection from '../components/BenefitsSection.jsx';
import LimitedSlotsSection from '../components/LimitedSlotsSection.jsx';
import CallToActionSection from '../components/CallToActionSection.jsx';
import AssessmentsSection from '../components/AssessmentsSection.jsx';
import BookSection from '../components/BookSection.jsx';
import FAQSection from '../components/FAQSection.jsx';
import Footer from '../components/Footer.jsx';

const PaginaPrincipal = () => {
  const [videoStarted, setVideoStarted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const isModalShown = sessionStorage.getItem('modalShown');

    if (!isModalShown) {
      const timer = setTimeout(() => {
        setShowModal(true);
      }, 3000); 
      
      sessionStorage.setItem('modalShown', 'true');

      return () => clearTimeout(timer);
    }
  }, []);

  const handleVideoStart = () => {
    setVideoStarted(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <ModalPopup show={showModal} handleClose={handleCloseModal} />
      <Navbar />
      <HeaderSection />
      <VideoSection videoStarted={videoStarted} handleVideoStart={handleVideoStart} />
      <InformationSection />
      <JoinSection />
      <GiftSection />
      <BenefitsSection />
      <LimitedSlotsSection />
      <CallToActionSection />
      <AssessmentsSection />
      <BookSection />
      <FAQSection />
      <Footer />
    </div>
  );
};

export default PaginaPrincipal;
