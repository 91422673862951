// components/FAQSection.js
import React, { useState } from 'react';
import faqItems from '../Utils/FAQData';

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleItem = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <section id="faqSection" className="py-5" data-aos="fade-right" data-aos-duration="1000">
      <div className="container">
        <h2 className="text-center mb-5 text-uppercase" style={{ color: '#c5c5c5', fontWeight: 'bold' }}>Preguntas Frecuentes</h2>
        <div className="accordion" id="accordionExample">
          {faqItems.map((item, index) => (
            <div key={index} className="card mb-3 box-spacing preguntas">
              <div
                className="card-header"
                id={`heading${index}`}
                onClick={() => toggleItem(index)}
                style={{ cursor: 'pointer' }}
              >
                <h2 className="mb-0">
                  <div
                    className="btn btn-link btn-block text-left btn-custom"
                    aria-expanded={activeIndex === index}
                    aria-controls={`collapse${index}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <span className="btn-text pregunta">{item.question}</span>
                    <span className="float-right">
                      <i className={`fas fa-chevron-${activeIndex === index ? 'up' : 'down'}`} aria-hidden="true"></i>
                    </span>
                  </div>
                </h2>
              </div>
              <div
                id={`collapse${index}`}
                className={`collapse ${activeIndex === index ? 'show' : ''}`}
                aria-labelledby={`heading${index}`}
                data-parent="#accordionExample"
              >
                <div className="card-body">
                  <p className="body-text respuesta">{item.answer}</p>
                  {item.link && (
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-outline-primary btn-block text-center rounded-5 btn-lg"
                    >
                      Consulta los términos y condiciones
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
