// components/CallToActionSection.js
import React from 'react';
import bannerImg from '../assets/imagenes_videos/BannerIsma.png';

const CallToActionSection = () => (
  <div className="call-to-action-section text-center" style={{ backgroundImage: `url(${bannerImg})`, backgroundSize: 'cover', backgroundPosition: 'center', padding: '50px 0' }}>
    <div className="container">
      <h2 className="text-light fw-bold mb-4" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>¿Tienes dudas?</h2>
      <a href="https://calendly.com/ismarodriguezfit/transforma-tu-vida-ahora" className="btn btn-success btn-lg" data-aos="fade-right">Agenda tu llamada ya</a>
    </div>
  </div>
);

export default CallToActionSection;
