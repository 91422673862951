// components/ModalPopup.js
import React from 'react';
import diarioGif from '../assets/imagenes_videos/diario.gif';

const ModalPopup = ({ show, handleClose }) => (
  <div className={`modal ${show ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: show ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
    <div className="modal-dialog modal-fullscreen" role="document">
      <div className="modal-content" style={{ backgroundColor: 'black', color: 'white' }}>
        <div className="modal-header" style={{ borderBottom: 'none', justifyContent: 'center', paddingTop: '50px' }}>
          <h4 className="modal-title text-center" style={{ fontFamily: 'MarvinVisions', color: '#5296FF', marginTop: '20px' }}>
            Primera edición del diario que revolucionará la forma en la entrenas
          </h4>
          <button type="button" className="close" onClick={handleClose} aria-label="Close" style={{ position: 'absolute', top: '30px', right: '10px', backgroundColor: 'white', border: 'none', padding: '5px' }}>
            <span aria-hidden="true" style={{ color: 'black', fontSize: '1.5rem', fontWeight: 'bold' }}>&times;</span>
          </button>
        </div>
        <div className="modal-body text-center">
          <img src={diarioGif} alt="diario" className="diarioBienvenida" onClick={() => window.location.href = 'https://buy.stripe.com/eVag0A3dJ59lcBGeVa'} style={{ cursor: 'pointer' }} />
          <h6 className="mt-2" style={{ fontFamily: 'MarvinVisions', color: '#5296FF' }}>No es solo lo que ves, sino lo que se encuentra en su interior</h6>
          <h6 style={{ fontFamily: 'MarvinVisions', color: '#5296FF' }}>Encuéntralo en mi asesoría semestral o resérvalo ya!</h6>
        </div>
      </div>
    </div>
  </div>
);

export default ModalPopup;
