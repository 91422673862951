// components/Footer.js
import React from 'react';

const Footer = () => (
  <footer className="text-white text-center py-4">
    <div className="container">
      <p>&copy; 2024 - ISMARFIT - Transforma tu Vida Fitness</p>
    </div>
  </footer>
);

export default Footer;
