// components/HeaderSection.js
import React from 'react';

const HeaderSection = () => (
  <header id="headerSection" className="text-center" data-aos="flip-right">
    <div className="container">
      <h1 className="frase-motivadora mb-5">¿Sabías que tenemos más de 35.000 decisiones diarias?</h1>
      <h2 className="frase-motivadora mb-5">Es hora de elegir bien la decisión que puede cambiar tu vida desde hoy.</h2>
    </div>
  </header>
);

export default HeaderSection;